<template>
  <div class="page">
    <PageTitle title="导入淘宝商品"></PageTitle>
    <div class="content">
      <div class="head flex-between">
        <img src="../../assets/icon/tbtp.png" alt="淘宝导入" class="tbtp-icon">
        <div class="rule">
          <p class="title">导入淘宝商品说明：</p>
          <span class="item">1.该功能可批量导入淘宝及天猫店对应商品。极大节省录入商品的时间，提升效率</span>
          <span class="item">2.请使用淘宝助理6.2.3版本导出对应的csv模版</span>
          <span class="item">3.导入文档格式参考下面的模版（可下载)</span>
          <span class="item">4.导入完成后，有部分重要商品字段比如商品简介以及对应的sku建议补充完整后再进行上架操作</span>
          <span class="item">5.刚导入的商品在同步商品详情会需要点时间，所以马上导入查看商品详情可能不会显示出来，需要耐心等待一段时间即可。</span>
        </div>
      </div>
      <div class="main flex-center" v-loading="loading">
        <el-form :model="edit" ref="ruleForm" class="tb-form" label-width="104px" label-position="right">
          <el-form-item label="数据包格式：">
            <el-input value="淘宝助理6.2.3" disabled style="width: 294px;"></el-input>
            <span class="tips ml-16">
              <img class="label-icon" src="../../assets/icon/label.png" alt="upload">
              <span>请下载最新版本的淘宝助理 </span>
              <a download="淘宝助理" href="https://download.alicdn.com/tbassisant/download/taobaoassistant6.2.3.0.exe" class="download">点击下载</a>
            </span>
          </el-form-item>
          <el-form-item label="关联品牌：">
            <el-select v-model="edit.brand_id" placeholder="请选择品牌" style="width: 294px;">
              <el-option :label="item.brandName" :value="item.id" v-for="item in brandOptions" :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上传csv文件：">
            <el-upload
              ref="upload"
              name="csv"
              accept=".csv"
              :file-list="fileList"
              :data="edit"
              :headers="{
                tbadminauthorization: token
              }"
              :auto-upload="false"
              :action="uploadUrl"
              :on-success="onSuccess"
              :on-error="onError"
              :on-remove="onRemove"
              :on-change="onChange"
              :on-progress="onProgress"
            >
              <el-button class="upload-btn" :disabled="loading">
                <span class="upload-icon" />
                点击上传
              </el-button>
              <span slot="tip" class="tips ml-16">
                <img class="label-icon" src="../../assets/icon/label.png" alt="upload">
                <span>请上传csv格式的文件，最多不超过50个商品 </span>
                <a download="参考模板" href="http://tbresource.oss-cn-hangzhou.aliyuncs.com/admin/%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF%E7%A4%BA%E4%BE%8B.csv" class="download">点击下载</a>
              </span>
            </el-upload>
          </el-form-item>
          <el-form-item label="导入状态：">
            <el-radio-group v-model="edit.status">
              <el-radio :label="0">下架</el-radio>
              <el-radio :label="1">上架</el-radio>
            </el-radio-group>
            <div class="tips">
              <span>注：建议初始导入状态为下架，完善商品信息后再更改上架状态</span>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onBegein" :disabled="loading">开始导入</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import $cookie from 'js-cookie'
import {USER_DATA} from '@/utils/StoreKey.js'
import {mapState} from 'vuex'
import PageTitle from '@/components/PageTitle'
export default {
  name: 'ImportTaobao',
  components: {
    PageTitle
  },
  computed: {
    ...mapState([
      'brandOptions'
    ]),
    uploadUrl() {
      const url = process.env.NODE_ENV === 'development' ? 'https://apitest.topboom0912.com/dd' : 'https://api.topboom0912.com/dd'
      return url + '/admin/import/tb/product'
    },
    token() {
      const USER = $cookie.get(USER_DATA) || null
      let TOKEN = ''
      if (USER) {
        TOKEN = JSON.parse(USER).TOKEN
      }
      return TOKEN
    }
  },
  data() {
    return {
      edit: {
        brand_id: '',
        status: 0
      },
      fileList: [],
      loading: false
    }
  },
  methods: {
    onBegein() {
      if (!this.edit.brand_id) {
        this.$message.error('请选择品牌')
        return
      }
      if (this.fileList.length === 0) {
        this.$message.error('请选择上传的文件')
        return
      }
      this.$confirm('导入成功后，建议至商品管理中检查导入商品的信息内容，请完善后再进行上架！', '确认要导入淘宝商品吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success'
      }).then(() => {
        this.$refs.upload.submit()
      }).catch(() => {})
    },
    onProgress() {
      this.loading = true
    },
    onSuccess(res) {
      if (res.code !== 200) {
        this.$message.error(res.msg || '文档上传失败，请稍后重试。')
      } else {
        this.$message.success(`成功导入${res.data}件淘宝商品`)
      }
      this.loading = false
    },
    onError() {
      this.$message.error('文档上传失败，请稍后重试。')
      this.loading = false
    },
    onRemove(file, fileList) {
      this.fileList = fileList
    },
    onChange(file, fileList) {
      this.fileList = fileList
    }
  }
}
</script>

<style scoped lang="scss">
.head {
  position: relative;
  flex-direction: column;
  padding: 44px 0 40px;
  height: 470px;
  box-sizing: border-box;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    transform: scaleY(.5);
    background-color: #E9E9E9;
  }
  .tbtp-icon {
    width: 217px;
    height: 61px;
  }
  .rule {
    width: 800px;
    padding: 32px;
    background-color: #F5F5F5;
    .title {
      font-size: 18px;
      font-weight: 600;
      margin: 0 0 16px 0;
    }
    .item {
      display: block;
      color: rgba($color: #000, $alpha: .65)
    }
    .item + .item {
      margin-top: 8px;
    }
  }
}
.main {
  padding-top: 40px;
  .upload-btn {
    &:hover .upload-icon,
    &:active .upload-icon {
      background-image: url('../../assets/icon/btn_upload_active.png');
    }
    .upload-icon {
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-right: 8px;
      background-image: url('../../assets/icon/btn_upload.png');
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
    }
  }
  .label-icon {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }
  .ml-16 {
    margin-left: 16px;
  }
  .tips {
    font-size: 14px;
    color: rgba($color: #000, $alpha: .45);
    span, img {
      vertical-align: middle;
    }
    .download {
      color: #1890FF;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
